<template>
    <div>{{agen}}</div>
</template>
<script>
export default {
    data(){
        return {
            agen:{}
        }
    },
    mounted(){
        this.agen = window.navigator.userAgentData.mobile;
    }
}
</script>